import type {
  addWidgetOptions,
  Layout,
  WidgetInstallationType,
} from '@wix/platform-editor-sdk';

export enum LightboxConnectionParam {
  Controller = 'popupController',
  Role = 'popupContainer',
}

export const FOLLOWING_FOLLOWERS_MODAL_WIDGET_ID =
  'a26fd26a-3dd9-42ca-b381-326a9c143e38';

enum FollowingFollowersPreset {
  Desktop = 'variants-luqscagi2',
  Mobile = 'variants-lvkx70kd',
}

export const FOLLOWING_FOLLOWERS_MODAL_WIDGET_OPTIONS: addWidgetOptions & {
  layout: Layout;
} = {
  widgetId: FOLLOWING_FOLLOWERS_MODAL_WIDGET_ID,
  installationType: 'closed' as WidgetInstallationType,
  layout: {
    fixedPosition: false,
    width: 620,
    height: 480,
    x: 0,
    y: 0,
  },
  scopedPresets: {
    mobile: {
      layout: FollowingFollowersPreset.Mobile,
      style: FollowingFollowersPreset.Mobile,
    },
    tablet: {
      layout: FollowingFollowersPreset.Desktop,
      style: FollowingFollowersPreset.Desktop,
    },
    desktop: {
      layout: FollowingFollowersPreset.Desktop,
      style: FollowingFollowersPreset.Desktop,
    },
  },
  presetIds: {
    layout: FollowingFollowersPreset.Desktop,
    style: FollowingFollowersPreset.Desktop,
  },
};
