import type { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

import type { FFModalBind } from '../../../../types/controller';
import type { FFModalState } from '../../../../types/followers';
import { getDemoFollowersFollowing } from './demo-members';

export const bindDemoData = async (
  state: FFModalState,
  bind: FFModalBind,
  t: PlatformControllerFlowAPI['translations']['t'],
) => {
  const followersFollowing = getDemoFollowersFollowing(t);

  bind('#title', {
    text: () => state.currentMember?.profile?.nickname!,
  });

  bind('#followers', {
    // @ts-expect-error
    label: () => t('app.ffmodal.tab.followers'),
  });

  bind('#following', {
    // @ts-expect-error
    label: () => t('app.ffmodal.tab.following'),
  });

  bind('#followingMsb', {
    currentState: () => 'followingContent',
  });

  bind('#followersMsb', {
    currentState: () => 'followersContent',
  });

  bind('#ffList1', {
    // @ts-expect-error
    members: () => followersFollowing,
  });

  bind('#ffList2', {
    // @ts-expect-error
    members: () => followersFollowing,
  });

  await state.fetchCurrentMember();
};
